._9Iy3HW_text:hover {
  cursor: pointer;
}

._9Iy3HW_list {
  border: 2px solid var(--peachy-pink);
  font-size: var(--font-size-small);
  border-radius: .5em;
  overflow-y: scroll;
}

._9Iy3HW_listItem {
  padding: .2em .5em;
}

._9Iy3HW_listItem:hover {
  background-color: var(--form-hover);
  cursor: pointer;
}

._9Iy3HW_selectedItem {
  color: #fff;
}

._9Iy3HW_selectedItem, ._9Iy3HW_selectedItem:hover {
  background-color: var(--peachy-pink);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7Ozs7OztBQU9BOzs7O0FBSUE7Ozs7O0FBS0E7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvUGxhbkJ1aWxkZXIvUGxhbkNvbmZpZ3VyYXRpb24vTGltaXQvTGltaXQubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIudGV4dDpob3ZlciB7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4ubGlzdCB7XG4gICAgYm9yZGVyOiAycHggc29saWQgdmFyKC0tcGVhY2h5LXBpbmspO1xuICAgIGJvcmRlci1yYWRpdXM6IDAuNWVtO1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXNtYWxsKTtcbiAgICBvdmVyZmxvdy15OiBzY3JvbGw7XG59XG5cbi5saXN0SXRlbSB7XG4gICAgcGFkZGluZzogMC4yZW0gMC41ZW07XG59XG5cbi5saXN0SXRlbTpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tZm9ybS1ob3Zlcik7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4uc2VsZWN0ZWRJdGVtIHtcbiAgICBjb2xvcjogd2hpdGU7XG59XG5cbi5zZWxlY3RlZEl0ZW0sIC5zZWxlY3RlZEl0ZW06aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBlYWNoeS1waW5rKTtcbn1cbiJdLCJuYW1lcyI6W119 */