.CtSL3q_WelcomeContainer {
  background-color: var(--primary-color-1);
  top: var(--header-height);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.CtSL3q_Welcome {
  color: #fff;
  text-align: center;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.CtSL3q_Welcome p {
  line-height: unset;
}

.CtSL3q_textContent {
  flex-direction: column;
  row-gap: 1em;
  width: 30em;
  padding-bottom: 1em;
  display: flex;
}

.CtSL3q_email, .CtSL3q_email > button {
  font-size: var(--font-size-root-xsmall);
  vertical-align: unset;
}

.CtSL3q_navigation {
  flex-direction: row;
  column-gap: 1em;
  display: flex;
}

.CtSL3q_navigation button {
  padding: .75em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7Ozs7Ozs7QUFVQTs7Ozs7Ozs7Ozs7OztBQWNBOzs7O0FBSUE7Ozs7Ozs7O0FBUUE7Ozs7O0FBTUE7Ozs7OztBQU1BIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvcXVvdGUvV2VsY29tZS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIlxuLldlbGNvbWVDb250YWluZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXByaW1hcnktY29sb3ItMSk7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogdmFyKC0taGVhZGVyLWhlaWdodCk7XG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgICBib3R0b206IDA7XG4gICAgbGVmdDogMDtcbiAgICByaWdodDogMDtcbn1cblxuLldlbGNvbWUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgcm93LWdhcDogMWVtO1xuXG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogNTAlO1xuICAgIGxlZnQ6IDUwJTtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgtNTAlLCAtNTAlKTtcbn1cblxuLldlbGNvbWUgcCB7XG4gICAgbGluZS1oZWlnaHQ6IHVuc2V0O1xufVxuXG4udGV4dENvbnRlbnQge1xuICAgIHdpZHRoOiAzMGVtO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAxZW07XG4gICAgcGFkZGluZy1ib3R0b206IDFlbTtcbn1cblxuLmVtYWlsLFxuLmVtYWlsID4gYnV0dG9uIHtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1yb290LXhzbWFsbCk7XG4gICAgdmVydGljYWwtYWxpZ246IHVuc2V0O1xufVxuXG4ubmF2aWdhdGlvbiB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGNvbHVtbi1nYXA6IDFlbTtcbn1cblxuLm5hdmlnYXRpb24gYnV0dG9uIHtcbiAgICBwYWRkaW5nOiAwLjc1ZW07XG59Il0sIm5hbWVzIjpbXX0= */