.qETUOa_modal {
  background-color: var(--surface-color);
  border: 1px solid var(--neutral-dark);
  filter: var(--modal-shadow);
  border-radius: .5em;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  max-height: 70vh;
  margin: auto;
  display: flex;
  position: relative;
  overflow-y: scroll;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL01vZGFsL0Rpc21pc3NpYmxlTW9kYWwubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIubW9kYWwge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1cmZhY2UtY29sb3IpO1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLW5ldXRyYWwtZGFyayk7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG4gICAgZmlsdGVyOiB2YXIoLS1tb2RhbC1zaGFkb3cpO1xuICAgIG1hcmdpbjogYXV0bztcbiAgICBtYXgtaGVpZ2h0OiA3MHZoO1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBvdmVyZmxvdy15OiBzY3JvbGw7XG4gICAgcm93LWdhcDogMWVtO1xufSJdLCJuYW1lcyI6W119 */