
export * from './src/SubscriptionApiGateway'


export class ContentBucketResponse {
    content_bucket_path: string
}


export class ReissuePolicyDocRequest {
    email: string

    constructor(email: string) {
        this.email = email
    }
}

export class ReissuePolicyDocResponse {
    done: boolean
}

        import * as me from '@peachy/subscription-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/clairechia/workspace/peachy-repo/comp-kit/subscription/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/subscription-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    