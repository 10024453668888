.YfyWeq_AddressLookup > label {
  width: 100%;
}

.YfyWeq_inputOverflow input[type="text"] {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2em;
  overflow: hidden;
}

.YfyWeq_invalidField input[type="text"], label.YfyWeq_invalidField {
  background-color: var(--peachy-pink-light-pale);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFLQTs7Ozs7Ozs7QUFRQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvTWFuYWdlTWVtYmVycy90YWJsZS9Qb2xpY3lSb3cubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuQWRkcmVzc0xvb2t1cCA+IGxhYmVsIHtcbiAgICB3aWR0aDogMTAwJTtcbn1cblxuXG4uaW5wdXRPdmVyZmxvdyBpbnB1dFt0eXBlPXRleHRdIHtcbiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94OyAvKiBTdG9wIGVsbGlwc2lzIGZyb20gb3ZlcmxhcHBpbmcgd2l0aCBpY29uKi9cbiAgICBwYWRkaW5nLXJpZ2h0OiAyZW07XG4gICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xufVxuXG4uaW52YWxpZEZpZWxkIGlucHV0W3R5cGU9dGV4dF0sXG5sYWJlbC5pbnZhbGlkRmllbGQge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBlYWNoeS1waW5rLWxpZ2h0LXBhbGUpXG59XG4iXSwibmFtZXMiOltdfQ== */