.Cni4UW_quoteHeader {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 100vw;
  padding: 3em;
  display: flex;
}

.Cni4UW_quoteHeader > p {
  text-align: center;
  max-width: 32em;
}

.Cni4UW_quoteHeader span {
  font-weight: 700;
  font-size: var(--font-size-small);
  cursor: default;
}

.Cni4UW_quoteHeader a {
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFVQTs7Ozs7QUFLQTs7Ozs7O0FBTUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1F1b3RlL1F1b3RlSGVhZGVyL1F1b3RlSGVhZGVyLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLnF1b3RlSGVhZGVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBwYWRkaW5nOiAzZW07XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiAyZW07XG4gICAgd2lkdGg6IDEwMHZ3O1xufVxuXG4ucXVvdGVIZWFkZXIgPiBwIHtcbiAgICBtYXgtd2lkdGg6IDMyZW07XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG4ucXVvdGVIZWFkZXIgc3BhbiB7XG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG4gICAgY3Vyc29yOiBkZWZhdWx0O1xufVxuXG4ucXVvdGVIZWFkZXIgYSB7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG59XG4iXSwibmFtZXMiOltdfQ== */