.PDXDLW_moreInfoModal {
  background-color: var(--surface-color);
  border: 1px solid var(--neutral-dark);
  filter: var(--modal-shadow);
  font-size: var(--font-size-small);
  border-radius: .5em;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  min-width: 35em;
  max-width: 50em;
  max-height: 70vh;
  margin: auto;
  padding: 2em 6em;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.PDXDLW_moreInfoModal > section {
  flex-direction: column;
  justify-items: flex-start;
  gap: .6em;
  margin-top: 1em;
  display: flex;
}

.PDXDLW_moreInfoModal ul {
  text-indent: -1.2rem;
  flex-direction: column;
  gap: .3em;
  padding-top: .3em;
  padding-left: 1.5em;
  list-style: inside;
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7QUFtQkE7Ozs7Ozs7O0FBUUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1BsYW5CdWlsZGVyL01vZGFsL01vcmVJbmZvLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLm1vcmVJbmZvTW9kYWwge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1cmZhY2UtY29sb3IpO1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLW5ldXRyYWwtZGFyayk7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG4gICAgZmlsdGVyOiB2YXIoLS1tb2RhbC1zaGFkb3cpO1xuICAgIG1hcmdpbjogYXV0bztcbiAgICBtYXgtaGVpZ2h0OiA3MHZoO1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBvdmVyZmxvdy15OiBzY3JvbGw7XG4gICAgcm93LWdhcDogMWVtO1xuICAgIHBhZGRpbmc6IDJlbSA2ZW07XG4gICAgbWF4LXdpZHRoOiA1MGVtO1xuICAgIG1pbi13aWR0aDogMzVlbTtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG59XG5cbi5tb3JlSW5mb01vZGFsID4gc2VjdGlvbiB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGp1c3RpZnktaXRlbXM6IGZsZXgtc3RhcnQ7XG4gICAgZ2FwOiAwLjZlbTtcbiAgICBtYXJnaW4tdG9wOiAxZW07XG59XG5cbi5tb3JlSW5mb01vZGFsIHVsIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgbGlzdC1zdHlsZTogZGlzYyBpbnNpZGU7XG4gICAgZ2FwOiAwLjNlbTtcbiAgICBwYWRkaW5nLXRvcDogMC4zZW07XG4gICAgcGFkZGluZy1sZWZ0OiAxLjVlbTtcbiAgICB0ZXh0LWluZGVudDogLTEuMnJlbTtcbn0iXSwibmFtZXMiOltdfQ== */