.CQvD1W_name {
  width: 300px;
}

.CQvD1W_dateIssued {
  width: 150px;
}

.CQvD1W_view {
  text-align: end;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFHQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL0RvY3VtZW50cy9Eb2N1bWVudHMubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIubmFtZSB7XG4gICAgd2lkdGg6IDMwMHB4XG59XG4uZGF0ZUlzc3VlZCB7XG4gICAgd2lkdGg6IDE1MHB4XG59XG5cbi52aWV3IHtcbiAgICB0ZXh0LWFsaWduOiBlbmQ7XG59Il0sIm5hbWVzIjpbXX0= */