import {GeoLocation} from '@peachy/web-shop-client'

export * from './src/hooks/createThrottledSignal'
export * from './src/hooks/createBufferedSignal'
export * from './src/hooks/createToggleSignal'
export * from './src/hooks/useServicePatch'
export * from './src/hooks/createListStore'

export * from './src/components/Expander/Expander'
export * from './src/components/Toggle/Toggle'
export * from './src/components/TextBox/TextBox'
export * from './src/components/DateBox/DateBox'
export * from './src/components/ListSelector/ListSelector'
export * from './src/components/AddressLookup/AddressLookup'

export * from './src/services/geo-location/GeoLocationServiceController'

export * from './src/components/DatePicker/DatePicker'
export * from './src/components/DatePicker/DatePickerStore'

export * from './src/components/Transition/Transition'
export * from './src/components/Modal/Modal'
export * from './src/components/Spinner/Spinner'

export * from './src/controllers/KeyboardController'
export * from './src/components/TreeView/TreeNode'
export * from './src/components/TreeView/TreeResolver'
export * from './src/components/RadioGroup/RadioGroup'

export * from './src/components/PopupListSelector/PopupListSelector'

export * from './src/controllers/DragScope/DragScope'

export * from './src/components/Autocomplete/Autocomplete'
export * from './src/components/Html5DatePicker/Html5DatePicker'
export * from './src/components/Select/Select'

export * from './src/components/ErrorWrapped'
export * from './src/components/Tabs/Tabs'
export * from './src/css-exports'

export * from './src/utils/csv-kit'

export * from './src/components/BenefitIcon'

export const PEACHY_HQ: GeoLocation = {
    lat: 51.5444444,
    lng: -0.0228583
}

export * from './src/state-machine/solid-state'

        import * as me from '@peachy/client-kit'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/clairechia/workspace/peachy-repo/client-kit'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/client-kit'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    