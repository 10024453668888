.wX-sOW_header {
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.wX-sOW_toggle > input {
  min-width: 4em;
  height: 2em;
  font-size: .6em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvcXVvdGUvQ2FwdHVyZUFkbWluVXNlci9DYXB0dXJlQWRtaW5FbWFpbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5oZWFkZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAxZW07XG59XG5cbi50b2dnbGUgPiBpbnB1dCB7XG4gICAgZm9udC1zaXplOiAwLjZlbTtcbiAgICBoZWlnaHQ6IDJlbTtcbiAgICBtaW4td2lkdGg6IDRlbTtcbn0iXSwibmFtZXMiOltdfQ== */