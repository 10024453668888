.bdLbra_ListSelector::-webkit-scrollbar {
  display: none;
}

.bdLbra_ListSelector {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  flex-direction: column;
  justify-content: start;
  display: flex;
  overflow-y: scroll;
}

.bdLbra_listItem:hover {
  cursor: pointer;
  background-color: #efefef;
}

.bdLbra_listItemSelected {
  background-color: #ddd;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7QUFDQTs7Ozs7Ozs7OztBQVdBOzs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9jbGllbnQta2l0L3NyYy9jb21wb25lbnRzL0xpc3RTZWxlY3Rvci9MaXN0U2VsZWN0b3IubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyJcbi5MaXN0U2VsZWN0b3I6Oi13ZWJraXQtc2Nyb2xsYmFyIHtkaXNwbGF5OiBub25lO31cbi5MaXN0U2VsZWN0b3Igey1tcy1vdmVyZmxvdy1zdHlsZTogbm9uZTsgc2Nyb2xsYmFyLXdpZHRoOiBub25lO31cblxuXG4uTGlzdFNlbGVjdG9yIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAganVzdGlmeS1jb250ZW50OiBzdGFydDtcbiAgICBvdmVyZmxvdy15OiBzY3JvbGw7XG4gICAgc2Nyb2xsLWJlaGF2aW9yOiBzbW9vdGg7XG59XG5cbi5saXN0SXRlbTpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogI0VGRUZFRjtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5saXN0SXRlbVNlbGVjdGVkIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiNEREREREQ7XG59XG5cblxuIl0sIm5hbWVzIjpbXX0= */