:root {
  --broker-company-typeahead-width: 580px;
}

.XKtJ3G_content {
  grid-template-columns: var(--broker-company-typeahead-width) 430px;
  justify-content: space-between;
  display: grid;
}

input[type="text"].XKtJ3G_invalid {
  background-color: var(--peachy-pink-light-pale);
}

.XKtJ3G_companyTypeahead {
  width: var(--broker-company-typeahead-width);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7Ozs7O0FBTUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL2Rhc2hib2FyZC9Ccm9rZXJRdW90ZS9Db21wYW55RGV0YWlscy9Db21wYW55RGV0YWlscy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIjpyb290IHtcbiAgICAtLWJyb2tlci1jb21wYW55LXR5cGVhaGVhZC13aWR0aDogNTgwcHhcbn1cblxuLmNvbnRlbnQge1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiB2YXIoLS1icm9rZXItY29tcGFueS10eXBlYWhlYWQtd2lkdGgpIDQzMHB4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2Vlbjtcbn1cblxuaW5wdXRbdHlwZT10ZXh0XS5pbnZhbGlkIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wZWFjaHktcGluay1saWdodC1wYWxlKTtcbn1cblxuLmNvbXBhbnlUeXBlYWhlYWQge1xuICAgIHdpZHRoOiB2YXIoLS1icm9rZXItY29tcGFueS10eXBlYWhlYWQtd2lkdGgpO1xufSJdLCJuYW1lcyI6W119 */