.WXdfPq_Exclusion {
  background-color: var(--neutral-color);
  border-radius: .5em;
}

.WXdfPq_Exclusion > div {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  display: flex;
}

.WXdfPq_Exclusion article {
  cursor: default;
  font-size: var(--font-size-small);
  flex-direction: column;
  row-gap: 1em;
  padding: 0 2em;
  display: flex;
}

.WXdfPq_Exclusion article > :last-child {
  padding-bottom: 2em;
}

.WXdfPq_Exclusion span {
  font-size: 2em;
}

.WXdfPq_Exclusion h6 {
  font-weight: 700;
}

.WXdfPq_Exclusion article > ul {
  grid-column-gap: 3em;
  grid-row-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 2em;
  list-style: disc;
  display: grid;
}

.WXdfPq_Exclusion article > p {
  display: inline-block;
}

@media screen and (width <= 600px) {
  .WXdfPq_Exclusion article > ul {
    column-count: 1;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7O0FBUUE7Ozs7Ozs7OztBQVVBOzs7O0FBSUE7Ozs7QUFJQTs7OztBQUlBOzs7Ozs7Ozs7QUFTQTs7OztBQUlBO0VBQ0kiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1doYXRzTm90Q292ZXJlZC9FeGNsdXNpb24vRXhjbHVzaW9uLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLkV4Y2x1c2lvbiB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tbmV1dHJhbC1jb2xvcik7XG4gICAgYm9yZGVyLXJhZGl1czogMC41ZW07XG59XG5cbi5FeGNsdXNpb24gPiBkaXYge1xuICAgIHBhZGRpbmc6IDFlbSAyZW07XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5FeGNsdXNpb24gYXJ0aWNsZSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHJvdy1nYXA6IDFlbTtcbiAgICBjdXJzb3I6IGRlZmF1bHQ7XG4gICAgcGFkZGluZzogMCAyZW07XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtc21hbGwpO1xufVxuXG5cbi5FeGNsdXNpb24gYXJ0aWNsZSA+ICo6bGFzdC1jaGlsZCB7XG4gICAgcGFkZGluZy1ib3R0b206IDJlbTtcbn1cblxuLkV4Y2x1c2lvbiBzcGFuIHtcbiAgICBmb250LXNpemU6IDJlbTtcbn1cblxuLkV4Y2x1c2lvbiBoNiB7XG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcbn1cblxuLkV4Y2x1c2lvbiBhcnRpY2xlID4gdWwge1xuICAgIGxpc3Qtc3R5bGU6IGRpc2M7XG4gICAgcGFkZGluZy1sZWZ0OiAyZW07XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCgzLCAxZnIpO1xuICAgIGdyaWQtY29sdW1uLWdhcDogM2VtO1xuICAgIGdyaWQtcm93LWdhcDogNXB4O1xufVxuXG4uRXhjbHVzaW9uIGFydGljbGUgPiBwIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDYwMHB4KSB7XG4gICAgLkV4Y2x1c2lvbiBhcnRpY2xlID4gdWwge1xuICAgICAgICBjb2x1bW4tY291bnQ6IDE7XG4gICAgfVxufSJdLCJuYW1lcyI6W119 */