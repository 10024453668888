.Xz4axW_Card {
  filter: var(--card-shadow);
  background-color: #fff;
  border-radius: 1em;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  display: flex;
}

.Xz4axW_highlight {
  background-color: var(--peachy-purple-light);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFXQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvQ2FyZC9DYXJkLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLkNhcmQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDFlbTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3JkZXItcmFkaXVzOiAxZW07XG4gICAgcGFkZGluZzogMWVtO1xuICAgIGZpbHRlcjogdmFyKC0tY2FyZC1zaGFkb3cpXG59XG5cblxuLmhpZ2hsaWdodCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGVhY2h5LXB1cnBsZS1saWdodClcbn1cbiJdLCJuYW1lcyI6W119 */