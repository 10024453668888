.irU2pG_SubHeader {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.irU2pG_SubHeader * {
  font-size: var(--font-size-root-xsmall);
}

.irU2pG_Edit {
  text-align: right;
}

.irU2pG_Edit > button {
  padding: unset;
  border: unset;
  background-color: unset;
  font-weight: normal;
}

.irU2pG_Edit i {
  margin-left: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQ0E7Ozs7O0FBS0E7Ozs7QUFJQTs7OztBQUlBOzs7Ozs7O0FBT0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9xdW90ZS9TdW1tYXJ5L1N1YkhlYWRlci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIlxuLlN1YkhlYWRlciB7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAxZnI7XG59XG5cbi5TdWJIZWFkZXIgKiB7XG4gICAgZm9udC1zaXplOiB2YXIoLS1mb250LXNpemUtcm9vdC14c21hbGwpO1xufVxuXG4uRWRpdCB7XG4gICAgdGV4dC1hbGlnbjogcmlnaHQ7XG59XG5cbi5FZGl0ID4gYnV0dG9uIHtcbiAgICBwYWRkaW5nOiB1bnNldDtcbiAgICBib3JkZXI6IHVuc2V0O1xuICAgIGJhY2tncm91bmQtY29sb3I6IHVuc2V0O1xuICAgIGZvbnQtd2VpZ2h0OiBub3JtYWw7XG59XG5cbi5FZGl0IGkge1xuICAgIG1hcmdpbi1sZWZ0OiA1cHg7XG59XG4iXSwibmFtZXMiOltdfQ== */