.q3U02G_PasswordBox {
  display: flex;
  position: relative;
}

.q3U02G_PasswordBox i {
  color: var(--peachy-pink);
  display: flex;
  position: absolute;
  top: 50%;
  right: .5em;
  transform: translateY(-50%);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9nbG9iYWwvZm9ybXMvUGFzc3dvcmRCb3gvUGFzc3dvcmRCb3gubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuUGFzc3dvcmRCb3gge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xufVxuXG4uUGFzc3dvcmRCb3ggaSB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgtNTAlKTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHJpZ2h0OjAuNWVtO1xuICAgIGNvbG9yOiB2YXIoLS1wZWFjaHktcGluayk7XG4gICAgdG9wOjUwJTtcbn1cbiJdLCJuYW1lcyI6W119 */