.QnOSlq_quoteFooter {
  background-color: var(--primary-color-1);
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 90px;
  max-height: 90px;
  padding: 0 8em;
  display: flex;
  position: sticky;
  bottom: 0;
}

@media screen and (width <= 1400px) {
  .QnOSlq_quoteFooter {
    font-size: var(--font-size-small);
  }
}

@media screen and (width <= 800px) {
  .QnOSlq_quoteFooter {
    padding-left: 2em;
    padding-right: 6em;
  }

  .QnOSlq_quoteFooter button {
    padding-left: 2em;
    padding-right: 2em;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7O0FBY0E7RUFDSTs7Ozs7QUFLSjtFQUNJOzs7OztFQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9RdW90ZS9RdW90ZUZvb3Rlci9RdW90ZUZvb3Rlci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5xdW90ZUZvb3RlciB7XG4gICAgcG9zaXRpb246IHN0aWNreTtcbiAgICBib3R0b206IDA7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcHJpbWFyeS1jb2xvci0xKTtcbiAgICBjb2xvcjogd2hpdGU7XG4gICAgcGFkZGluZzogMCA4ZW07XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICB3aWR0aDogMTAwdnc7XG4gICAgaGVpZ2h0OiA5MHB4O1xuICAgIG1heC1oZWlnaHQ6IDkwcHg7XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDE0MDBweCkge1xuICAgIC5xdW90ZUZvb3RlciB7XG4gICAgICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXNtYWxsKTtcbiAgICB9XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDgwMHB4KSB7XG4gICAgLnF1b3RlRm9vdGVyIHtcbiAgICAgICAgcGFkZGluZy1sZWZ0OiAyZW07XG4gICAgICAgIHBhZGRpbmctcmlnaHQ6IDZlbTtcbiAgICB9XG5cbiAgICAucXVvdGVGb290ZXIgYnV0dG9uIHtcbiAgICAgICAgcGFkZGluZy1sZWZ0OiAyZW07XG4gICAgICAgIHBhZGRpbmctcmlnaHQ6IDJlbTtcbiAgICB9XG59Il0sIm5hbWVzIjpbXX0= */