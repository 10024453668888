.o02Hfa_annualLimitInfo {
  cursor: default;
}

.o02Hfa_annualLimitInfo > mark {
  font-weight: 700;
}

.o02Hfa_annualLimitInfoModal {
  background-color: var(--surface-color);
  border: 1px solid var(--neutral-dark);
  filter: var(--modal-shadow);
  border-radius: .5em;
  position: relative;
}

.o02Hfa_annualLimitInfoModal > section {
  cursor: default;
  flex-direction: column;
  justify-items: flex-start;
  row-gap: 2em;
  min-width: 35em;
  max-width: 50em;
  max-height: 70vh;
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 2em 6em;
  display: flex;
  overflow: scroll;
}

.o02Hfa_annualLimitInfoModal > section dl {
  grid-template: auto / 1fr 1fr;
  column-gap: 1em;
  width: fit-content;
  display: grid;
}

.o02Hfa_annualLimitInfoModal > section h6 {
  font-weight: bold;
}

.o02Hfa_annualLimitInfoModal > section :is(dl, p) {
  line-height: 1.5em;
}

.o02Hfa_annualLimitInfoModal > section cite {
  font-size: var(--font-size-small);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7OztBQUlBOzs7Ozs7OztBQVFBOzs7Ozs7Ozs7Ozs7Ozs7QUFlQTs7Ozs7OztBQU9BOzs7O0FBSUE7Ozs7QUFJQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvQW5udWFsTGltaXRJbmZvL0FubnVhbExpbWl0SW5mby5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5hbm51YWxMaW1pdEluZm8ge1xuICAgIGN1cnNvcjogZGVmYXVsdDtcbn1cblxuLmFubnVhbExpbWl0SW5mbyA+IG1hcmsge1xuICAgIGZvbnQtd2VpZ2h0OiA3MDA7XG59XG5cbi5hbm51YWxMaW1pdEluZm9Nb2RhbCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tc3VyZmFjZS1jb2xvcik7XG4gICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tbmV1dHJhbC1kYXJrKTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBmaWx0ZXI6IHZhcigtLW1vZGFsLXNoYWRvdyk7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xufVxuXG4uYW5udWFsTGltaXRJbmZvTW9kYWwgPiBzZWN0aW9uIHtcbiAgICBjdXJzb3I6IGRlZmF1bHQ7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGp1c3RpZnktaXRlbXM6IGZsZXgtc3RhcnQ7XG4gICAgcm93LWdhcDogMmVtO1xuICAgIG1hcmdpbi10b3A6IDFlbTtcbiAgICBtYXJnaW4tYm90dG9tOiAyZW07XG4gICAgcGFkZGluZzogMmVtIDZlbTtcbiAgICBtYXgtd2lkdGg6IDUwZW07XG4gICAgbWluLXdpZHRoOiAzNWVtO1xuICAgIG1heC1oZWlnaHQ6IDcwdmg7XG4gICAgb3ZlcmZsb3c6IHNjcm9sbDtcbn1cblxuLmFubnVhbExpbWl0SW5mb01vZGFsID4gc2VjdGlvbiBkbCB7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBjb2x1bW4tZ2FwOiAxZW07XG4gICAgZ3JpZC10ZW1wbGF0ZTogYXV0byAvIDFmciAxZnI7XG4gICAgd2lkdGg6IGZpdC1jb250ZW50O1xufVxuXG4uYW5udWFsTGltaXRJbmZvTW9kYWwgPiBzZWN0aW9uIGg2IHtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbn1cblxuLmFubnVhbExpbWl0SW5mb01vZGFsID4gc2VjdGlvbiA6aXMoZGwscCkge1xuICAgIGxpbmUtaGVpZ2h0OiAxLjVlbTtcbn1cblxuLmFubnVhbExpbWl0SW5mb01vZGFsID4gc2VjdGlvbiBjaXRlIHtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG59Il0sIm5hbWVzIjpbXX0= */