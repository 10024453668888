.g4DUXa_label:hover {
  cursor: unset;
}

.g4DUXa_planDownloadIcon {
  width: 3em;
}

.g4DUXa_planDownloadIcon:hover {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9QbGFuQnVpbGRlci9QbGFuRG93bmxvYWQvUGxhbkRvd25sb2Fkcy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5sYWJlbDpob3ZlciB7XG4gICAgY3Vyc29yOiB1bnNldDtcbn1cblxuLnBsYW5Eb3dubG9hZEljb24ge1xuICAgIHdpZHRoOiAzZW07XG59XG5cbi5wbGFuRG93bmxvYWRJY29uOmhvdmVyIHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59Il0sIm5hbWVzIjpbXX0= */