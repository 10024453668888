.m7t6kW_infoPanel {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  row-gap: .5em;
  width: 17em;
  padding: 1em;
  display: flex;
}

.m7t6kW_infoPanel > h5 {
  font-size: var(--font-size-normal);
}

.m7t6kW_infoPanel p {
  font-size: .75rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFVQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL3BhbmVscy9wdXJlL0luZm9QYW5lbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5pbmZvUGFuZWwge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICB3aWR0aDogMTdlbTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICAgIHJvdy1nYXA6IDAuNWVtO1xuICAgIHBhZGRpbmc6IDFlbTtcbn1cblxuLmluZm9QYW5lbCA+IGg1IHtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1ub3JtYWwpO1xuIH1cblxuLmluZm9QYW5lbCBwIHtcbiAgICBmb250LXNpemU6IDAuNzVyZW07XG59XG4iXSwibmFtZXMiOltdfQ== */