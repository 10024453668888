.NUgnjG_planDetails {
  font-size: var(--font-size-small);
  flex-direction: column;
  row-gap: 1em;
  display: flex;
}

.NUgnjG_planDocLinks > a {
  font-weight: 700;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL3F1b3RlL0NhcHR1cmVQbGFuRGV0YWlscy9DYXB0dXJlUGxhbkRldGFpbHMubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIucGxhbkRldGFpbHMge1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tZm9udC1zaXplLXNtYWxsKTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgcm93LWdhcDogMWVtO1xufVxuXG4ucGxhbkRvY0xpbmtzID4gYSB7XG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcbn0iXSwibmFtZXMiOltdfQ== */