.xN_qza_infoPanels {
  display: grid;
}

.xN_qza_infoPanels > div {
  min-height: 150px;
}

.xN_qza_infoPanels > div:nth-child(2) {
  justify-self: center;
}

.xN_qza_infoPanels > div:last-child {
  justify-self: end;
}

.xN_qza_infoPanels span {
  color: var(--primary-color-1);
  font-weight: bold;
}

.xN_qza_yourPlanInfoPanels {
  grid-template-columns: 1fr 1fr 1fr;
}

.xN_qza_billingInfoPanels {
  grid-template-columns: 1fr 1fr;
  width: 38em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7OztBQUlBOzs7O0FBSUE7Ozs7QUFJQTs7Ozs7QUFLQTs7OztBQUlBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvZGFzaGJvYXJkL3BhbmVscy9JbmZvUGFuZWxzLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLmluZm9QYW5lbHMge1xuICAgIGRpc3BsYXk6IGdyaWQ7XG59XG5cbi5pbmZvUGFuZWxzID4gZGl2IHtcbiAgICBtaW4taGVpZ2h0OiAxNTBweDtcbn1cblxuLmluZm9QYW5lbHMgPiBkaXY6bnRoLWNoaWxkKDIpIHtcbiAgICBqdXN0aWZ5LXNlbGY6IGNlbnRlcjtcbn1cblxuLmluZm9QYW5lbHMgPiBkaXY6bGFzdC1jaGlsZCB7XG4gICAganVzdGlmeS1zZWxmOiBlbmQ7XG59XG5cbi5pbmZvUGFuZWxzIHNwYW4ge1xuICAgIGNvbG9yOiB2YXIoLS1wcmltYXJ5LWNvbG9yLTEpO1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xufVxuXG4ueW91clBsYW5JbmZvUGFuZWxzIHtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAxZnIgMWZyO1xufVxuXG4uYmlsbGluZ0luZm9QYW5lbHMge1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmcjtcbiAgICB3aWR0aDogMzhlbTtcbn0iXSwibmFtZXMiOltdfQ== */