.R8a3hq_Help {
  cursor: default;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  padding: 3rem;
  display: flex;
}

.R8a3hq_Help > p {
  text-align: center;
  max-width: 23rem;
}

.R8a3hq_Help > div {
  column-gap: 1rem;
  display: flex;
}

.R8a3hq_Help > div button {
  border-width: 2px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7OztBQVNBOzs7OztBQUtBOzs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9IZWxwL0hlbHAubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuSGVscCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgcm93LWdhcDogMnJlbTtcbiAgICBwYWRkaW5nOiAzcmVtO1xuICAgIGN1cnNvcjogZGVmYXVsdDtcbn1cblxuLkhlbHAgPiBwIHtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgbWF4LXdpZHRoOiAyM3JlbTtcbn1cblxuLkhlbHAgPiBkaXYge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgY29sdW1uLWdhcDogMXJlbTtcbn1cblxuLkhlbHAgPiBkaXYgYnV0dG9uIHtcbiAgICBib3JkZXItd2lkdGg6IDJweDtcbn0iXSwibmFtZXMiOltdfQ== */