.VmatFa_header {
  height: var(--header-height);
  background: var(--header-color);
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  display: grid;
}

.VmatFa_header button {
  font-size: 2em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBU0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL0hlYWRlci9IZWFkZXIubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuaGVhZGVyIHtcbiAgICBoZWlnaHQ6IHZhcigtLWhlYWRlci1oZWlnaHQpOztcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktaXRlbXM6IGNlbnRlcjtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmciAxZnI7XG4gICAgYmFja2dyb3VuZDogdmFyKC0taGVhZGVyLWNvbG9yKTtcbn1cblxuLmhlYWRlciBidXR0b24ge1xuICAgIGZvbnQtc2l6ZTogMmVtO1xufVxuIl0sIm5hbWVzIjpbXX0= */