.JduNVG_Autocomplete {
  display: inline-block;
  position: relative;
}

.JduNVG_clickable:hover {
  cursor: pointer;
}

.JduNVG_hidden {
  display: none;
}

.JduNVG_choiceList {
  border: 2px solid var(--peachy-pink);
  background-color: #fff;
  border-radius: .5em;
  outline: none;
  min-width: 25em;
  height: 7em;
  transform: translateY(2.4em);
}

.JduNVG_choiceListItem {
  padding: .2em 1em;
  display: block;
}

.JduNVG_choiceListItem:hover {
  cursor: pointer;
  background-color: #ffe4e1;
}

.JduNVG_selectedChoiceListItem {
  background-color: var(--peachy-pink);
  color: #fff;
}

.JduNVG_tag {
  background-color: var(--peachy-green-light);
  border-radius: .5ch;
  margin-top: .5ch;
  margin-left: .5ch;
  padding: .5ch;
  display: inline-block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7QUFJQTs7OztBQUlBOzs7Ozs7Ozs7O0FBVUE7Ozs7O0FBS0E7Ozs7O0FBS0E7Ozs7O0FBS0EiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL2NsaWVudC1raXQvc3JjL2NvbXBvbmVudHMvQXV0b2NvbXBsZXRlL0F1dG9jb21wbGV0ZS5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5BdXRvY29tcGxldGUge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG59XG5cbi5jbGlja2FibGU6aG92ZXIge1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbn1cblxuLmhpZGRlbiB7XG4gICAgZGlzcGxheTogbm9uZTtcbn1cblxuLmNob2ljZUxpc3Qge1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBvdXRsaW5lOiBub25lO1xuICAgIGhlaWdodDogN2VtO1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgyLjRlbSk7XG4gICAgbWluLXdpZHRoOiAyNWVtO1xufVxuXG4uY2hvaWNlTGlzdEl0ZW0ge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIHBhZGRpbmc6IDAuMmVtIDFlbTtcbn1cblxuLmNob2ljZUxpc3RJdGVtOmhvdmVyIHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogbWlzdHlyb3NlO1xufVxuXG4uc2VsZWN0ZWRDaG9pY2VMaXN0SXRlbSB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGVhY2h5LXBpbmspO1xuICAgIGNvbG9yOiB3aGl0ZVxufVxuXG4udGFnIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGVhY2h5LWdyZWVuLWxpZ2h0KTtcbiAgICBwYWRkaW5nOiAwLjVjaDtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVjaDtcbiAgICBtYXJnaW4tdG9wOiAwLjVjaDtcbiAgICBtYXJnaW4tbGVmdDogMC41Y2g7XG59XG4iXSwibmFtZXMiOltdfQ== */