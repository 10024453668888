.LWYaPa_ModalPane {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.LWYaPa_ModalPaneCentered {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.LWYaPa_ModalContentCentered {
  transform: translateX(-50%);
}

.LWYaPa_ModalPane > * {
  width: fit-content;
}

.LWYaPa_blur {
  background-color: #0009;
  animation: .2s ease-out forwards LWYaPa_blurAnimation;
}

@keyframes LWYaPa_blurAnimation {
  0% {
    backdrop-filter: blur();
  }

  100% {
    backdrop-filter: blur(.1em);
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBUUE7Ozs7OztBQU9BOzs7O0FBS0E7Ozs7QUFJQTs7Ozs7QUFLQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vY2xpZW50LWtpdC9zcmMvY29tcG9uZW50cy9Nb2RhbC9Nb2RhbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5Nb2RhbFBhbmUge1xuICAgIHBvc2l0aW9uOiBmaXhlZDtcbiAgICB0b3A6IDA7XG4gICAgbGVmdDowO1xuICAgIHdpZHRoOiAxMDB2dztcbiAgICBtaW4taGVpZ2h0OiAxMDB2aDtcbn1cblxuLk1vZGFsUGFuZUNlbnRlcmVkIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtZXZlbmx5O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cblxuLk1vZGFsQ29udGVudENlbnRlcmVkIHtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7XG59XG5cblxuLk1vZGFsUGFuZT4qIHtcbiAgICB3aWR0aDogZml0LWNvbnRlbnQ7XG59XG5cbi5ibHVyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjMDAwMDAwOTk7XG4gICAgYW5pbWF0aW9uOiBibHVyQW5pbWF0aW9uIDIwMG1zIGVhc2Utb3V0IGZvcndhcmRzO1xufVxuXG5Aa2V5ZnJhbWVzIGJsdXJBbmltYXRpb24ge1xuICAgIDAlIHtcbiAgICAgICAgYmFja2Ryb3AtZmlsdGVyOiBibHVyKDApO1xuICAgIH1cbiAgICAxMDAlIHtcbiAgICAgICAgYmFja2Ryb3AtZmlsdGVyOiBibHVyKDAuMWVtKTtcbiAgICB9XG59XG4iXSwibmFtZXMiOltdfQ== */