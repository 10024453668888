._2elZna_form {
  flex-direction: column;
  row-gap: 1.5em;
  display: flex;
}

._2elZna_form fieldset {
  align-self: stretch;
  column-gap: 1em;
  line-height: 1em;
  display: flex;
}

._2elZna_form fieldset div {
  border: 1px solid var(--neutral-dark);
  background-color: #fff;
  border-radius: .5em;
  width: 100%;
  padding: .5em .75em;
  line-height: 1em;
}

._2elZna_form button {
  align-self: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7Ozs7O0FBT0E7Ozs7Ozs7OztBQVNBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvcGFnZXMvcXVvdGUvQ2FwdHVyZVBheW1lbnREZXRhaWxzL0NhcHR1cmVQYXltZW50RGV0YWlscy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5mb3JtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgcm93LWdhcDogMS41ZW07XG59XG5cbi5mb3JtIGZpZWxkc2V0IHtcbiAgICBsaW5lLWhlaWdodDogMWVtO1xuICAgIGFsaWduLXNlbGY6IHN0cmV0Y2g7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBjb2x1bW4tZ2FwOiAxZW07XG59XG5cbi5mb3JtIGZpZWxkc2V0IGRpdiB7XG4gICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tbmV1dHJhbC1kYXJrKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBwYWRkaW5nOiAwLjVlbSAwLjc1ZW07XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgbGluZS1oZWlnaHQ6IDFlbTtcbn1cblxuLmZvcm0gYnV0dG9uIHtcbiAgICBhbGlnbi1zZWxmOiBjZW50ZXI7XG59XG4iXSwibmFtZXMiOltdfQ== */