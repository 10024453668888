.SZhGoq_Tabs {
  width: 100%;
}

.SZhGoq_Tabs > ul {
  flex-direction: row;
  display: flex;
}

.SZhGoq_Tabs > ul > li {
  cursor: pointer;
  opacity: .6;
  color: var(--text-color);
  border: 1px solid var(--pencil-color);
  background-color: #fff;
  border-radius: .5em .5em 0 0;
  padding: .5em;
}

.SZhGoq_Tabs > ul > li[data-is-selected="true"] {
  opacity: 1;
  border-bottom: none;
}

.SZhGoq_Tabs > div {
  border: 1px solid var(--pencil-color);
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 .5em .5em;
  padding: 1em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7QUFJQTs7Ozs7QUFLQTs7Ozs7Ozs7OztBQVdBOzs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9jbGllbnQta2l0L3NyYy9jb21wb25lbnRzL1RhYnMvUGVhY2h5VGFicy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5UYWJzIHtcbiAgICB3aWR0aDogMTAwJTtcbn1cblxuLlRhYnM+dWwge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbn1cblxuLlRhYnM+dWw+bGkge1xuICAgIHBhZGRpbmc6IDAuNWVtO1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbSAwLjVlbSAwIDA7XG4gICAgb3BhY2l0eTogMC42O1xuICAgIGNvbG9yOiB2YXIoLS10ZXh0LWNvbG9yKTtcbiAgICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1wZW5jaWwtY29sb3IpO1xufVxuXG5cbi5UYWJzPnVsPmxpW2RhdGEtaXMtc2VsZWN0ZWQ9dHJ1ZV0ge1xuICAgIGJvcmRlci1ib3R0b206IG5vbmU7XG4gICAgb3BhY2l0eTogMTtcbn1cblxuLlRhYnM+ZGl2IHtcbiAgICBwYWRkaW5nOiAxZW07XG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gICAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tcGVuY2lsLWNvbG9yKTtcbiAgICBib3JkZXItdG9wOm5vbmU7XG4gICAgYm9yZGVyLXJhZGl1czogMCAwIDAuNWVtIDAuNWVtO1xufVxuIl0sIm5hbWVzIjpbXX0= */