._2CJW3W_TouchScreen {
  justify-content: center;
  align-items: center;
  min-width: 320px;
  max-width: 600px;
  height: 90vh;
  padding: 1em;
  display: flex;
}

@media (width >= 768px) {
  ._2CJW3W_TouchScreen {
    font-size: 1.3em;
  }
}

@media (width >= 900px) {
  ._2CJW3W_TouchScreen {
    font-size: 1.6em;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7QUFZQTtFQUNJOzs7OztBQU1KO0VBQ0kiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9xdW90ZS9Ub3VjaFNjcmVlbi5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5Ub3VjaFNjcmVlbiB7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGhlaWdodDogOTB2aDtcbiAgICBtaW4td2lkdGg6IDMyMHB4O1xuICAgIHBhZGRpbmc6IDFlbTtcbiAgICBtYXgtd2lkdGg6IDYwMHB4O1xufVxuXG5cbi8qTWVkaXVtIGRldmljZXMgKHRhYmxldHMsIDc2OHB4IGFuZCB1cCkqL1xuQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gICAgLlRvdWNoU2NyZWVuIHtcbiAgICAgICAgZm9udC1zaXplOiAxLjNlbTtcbiAgICB9XG59XG5cbi8qTGFyZ2UgZGV2aWNlcyAoZGVza3RvcHMsIDkwMHB4IGFuZCB1cCkqL1xuQG1lZGlhIChtaW4td2lkdGg6IDkwMHB4KSB7XG4gICAgLlRvdWNoU2NyZWVuIHtcbiAgICAgICAgZm9udC1zaXplOiAxLjZlbTtcbiAgICB9XG59XG5cbiJdLCJuYW1lcyI6W119 */