.H8hBlq_modal {
  min-width: 70em;
  max-width: 80%;
}

.H8hBlq_modal section {
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  padding: 2em 2em 1em;
  display: flex;
  overflow-y: scroll;
}

.H8hBlq_modal section, .H8hBlq_modal .H8hBlq_footerContainer {
  width: 100%;
}

.H8hBlq_modal p {
  font-size: var(--font-size-small);
  text-align: justify;
  text-align-last: center;
  padding: 0 15%;
}

.H8hBlq_modal h3, p {
  cursor: default;
}

.H8hBlq_table {
  width: inherit;
}

.H8hBlq_footerContainer {
  grid-template-columns: 1fr 2fr;
  padding-bottom: 1em;
  display: grid;
}

.H8hBlq_footerContainer button {
  padding-left: 2em;
  padding-right: 2em;
}

.H8hBlq_footerContainer .H8hBlq_add {
  grid-column-start: 1;
  width: max-content;
}

.H8hBlq_footerContainer .H8hBlq_add button {
  margin-right: 1em;
  font-weight: normal;
}

.H8hBlq_footerContainer .H8hBlq_done {
  text-align: right;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0FBS0E7Ozs7Ozs7OztBQVNBOzs7O0FBSUE7Ozs7Ozs7QUFPQTs7OztBQUlBOzs7O0FBSUE7Ozs7OztBQU1BOzs7OztBQUtBOzs7OztBQUtBOzs7OztBQUtBIiwic291cmNlcyI6WyIuLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi8uLi9zcmMvY29tcG9uZW50cy9NYW5hZ2VNZW1iZXJzL21vZGFsL01hbmFnZU1lbWJlcnNNb2RhbC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5tb2RhbCB7XG4gICAgbWF4LXdpZHRoOiA4MCU7XG4gICAgbWluLXdpZHRoOiA3MGVtO1xufVxuXG4ubW9kYWwgc2VjdGlvbiB7XG4gICAgcGFkZGluZzogMmVtIDJlbSAxZW0gMmVtO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAxZW07XG4gICAgb3ZlcmZsb3cteTogc2Nyb2xsO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5tb2RhbCBzZWN0aW9uLCAubW9kYWwgLmZvb3RlckNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG59XG5cbi5tb2RhbCBwIHtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG4gICAgcGFkZGluZzogMCAxNSU7XG4gICAgdGV4dC1hbGlnbjoganVzdGlmeTtcbiAgICB0ZXh0LWFsaWduLWxhc3Q6IGNlbnRlcjtcbn1cblxuLm1vZGFsIGgzLCBwIHtcbiAgICBjdXJzb3I6IGRlZmF1bHQ7XG59XG5cbi50YWJsZSB7XG4gICAgd2lkdGg6IGluaGVyaXQ7XG59XG5cbi5mb290ZXJDb250YWluZXIge1xuICAgIHBhZGRpbmctYm90dG9tOiAxZW07XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciAyZnI7XG59XG5cbi5mb290ZXJDb250YWluZXIgYnV0dG9uIHtcbiAgICBwYWRkaW5nLWxlZnQ6IDJlbTtcbiAgICBwYWRkaW5nLXJpZ2h0OiAyZW07XG59XG5cbi5mb290ZXJDb250YWluZXIgLmFkZCB7XG4gICAgZ3JpZC1jb2x1bW4tc3RhcnQ6IDE7XG4gICAgd2lkdGg6IG1heC1jb250ZW50O1xufVxuXG4uZm9vdGVyQ29udGFpbmVyIC5hZGQgYnV0dG9uIHtcbiAgICBmb250LXdlaWdodDogbm9ybWFsO1xuICAgIG1hcmdpbi1yaWdodDogMWVtO1xufVxuXG4uZm9vdGVyQ29udGFpbmVyIC5kb25lIHtcbiAgICB0ZXh0LWFsaWduOiByaWdodDtcbn1cbiJdLCJuYW1lcyI6W119 */